import { ArticlesList } from "../components/articles-list/ArticlesList"
import { BlogSlider } from "../components/blog-slider/blog-slider"
import { Container } from "../components/shared"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import React, { FC } from "react"
import { ShortArticle } from "../types/ShortArticle"

interface BlogPageProps {
  data: {
    articles: {
      nodes: ShortArticle[]
    }
    translationData: {
      frontmatter: {
        navigationItems: {
          about: string
          blog: string
          contacts: string
          team: string
        }
        contactUsTitle: string
        address: string
      }
    }
    commonData: {
      frontmatter: {
        phoneNumber: string
        email: string
        addressCoordinates: {
          lat: number
          lng: number
        }
      }
    }
  }
}

const BlogPage: FC<BlogPageProps> = ({
  data: {
    articles,
    commonData: {
      frontmatter: { phoneNumber, email, addressCoordinates },
    },
    translationData: {
      frontmatter: { address, navigationItems, contactUsTitle },
    },
  },
}) => {
  return (
    <Layout
      lang="ua"
      phoneNumber={phoneNumber}
      email={email}
      address={address}
      navigationItems={navigationItems}
      addressCoordinates={addressCoordinates}
      contactUsTitle={contactUsTitle}
    >
      <Container padding={`32px 32px 0`}>
        {articles?.nodes.length && (
          <>
            <BlogSlider
              images={articles.nodes
                .slice(0, 3)
                .map(item => item.frontmatter.titleImage)}
            ></BlogSlider>
            <ArticlesList articles={articles.nodes}></ArticlesList>
          </>
        )}
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    articles: allMarkdownRemark(
      filter: { frontmatter: { pageType: { eq: "blog" }, lang: { eq: "ua" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          date(fromNow: true)
          pageType
          lang
          titleImage
        }
        excerpt(pruneLength: 300, truncate: true, format: HTML)
      }
    }
    commonData: markdownRemark(frontmatter: { pageType: { eq: "common" } }) {
      frontmatter {
        phoneNumber
        email
        addressCoordinates {
          lat
          lng
        }
      }
    }
    translationData: markdownRemark(
      frontmatter: { lang: { eq: "ua" }, pageType: { eq: "translations" } }
    ) {
      frontmatter {
        navigationItems {
          about
          blog
          contacts
          team
        }
        contactUsTitle
        address
      }
    }
  }
`

export default BlogPage
